import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selector: String,
    requiredSelector: String,
    css: String,
    when: String,
    unless: String
  }

  connect() {
    this.update()
  }

  update() {
    if (this.whenValue !== "") {
      this.whenUpdate()
    }
    if (this.unlessValue !== "") {
      this.unlessUpdate()
    }
  }

  whenUpdate() {
    if (this.element.value === this.whenValue) {
      this.addCss();
      this.removeRequired();
    } else {
      this.removeCss()
      this.addRequired()
    }
  }

  unlessUpdate() {
    if (this.element.value !== this.unlessValue) {
      this.addCss();
      this.removeRequired();
    } else {
      this.removeCss()
      this.addRequired()
    }
  }

  addCss() {
    if (this.selectorValue === "" || this.cssValue === "") {
      return
    }

    document.querySelectorAll(this.selectorValue).forEach((el) => {
      el.classList.add(this.cssValue)
    })
  }

  removeCss() {
    if (this.selectorValue === "" || this.cssValue === "") {
      return
    }

    document.querySelectorAll(this.selectorValue).forEach((el) => {
      el.classList.remove(this.cssValue)
    })
  }

  addRequired() {
    if (this.requiredSelectorValue === "") {
      return
    }

    document.querySelectorAll(this.requiredSelectorValue).forEach((el) => {
      el.setAttribute("required", true)
    })
  }

  removeRequired() {
    if (this.requiredSelectorValue === "") {
      return
    }

    document.querySelectorAll(this.requiredSelectorValue).forEach((el) => {
      el.removeAttribute("required")
    })
  }
}
